<template>
  <div class="search_filter flex-r-sa-c">
    <div v-if="type === 2 || type === 3|| type === 4"
         :class="search_filter_tab === 5?'search_filter_item flex-r-c-c search_filter_active' : 'search_filter_item flex-r-c-c'"
         @click="changeFilter(5)"
    >
      <span>分类</span>
      <van-image v-if="saleNum === 51" width="0.32rem" height="0.19rem"
                 src="https://oss.xiaoyi120.com/shop2.0/serClassfly/search_filter_2.png"
      />
      <van-image v-else width="0.32rem" height="0.19rem"
                 src="https://oss.xiaoyi120.com/shop2.0/serClassfly/search_filter_1.png"
      />
    </div>
    <div v-if="type === 2 || type === 3"
         :class="search_filter_tab === 6?'search_filter_item flex-r-c-c search_filter_active' : 'search_filter_item flex-r-c-c'"
         @click="changeFilter(6)"
    >
      <span>品牌</span>
      <van-image v-if="saleNum === 61" width="0.32rem" height="0.19rem"
                 src="https://oss.xiaoyi120.com/shop2.0/serClassfly/search_filter_2.png"
      />
      <van-image v-else width="0.32rem" height="0.19rem"
                 src="https://oss.xiaoyi120.com/shop2.0/serClassfly/search_filter_1.png"
      />
    </div>
    <div :class="search_filter_tab === 2?'search_filter_item flex-r-c-c search_filter_active' : 'search_filter_item flex-r-c-c'"
         @click="changeFilter(2)"
    >
      <span>销量</span>
      <van-image v-if="saleNum === 21" width="0.32rem" height="0.37rem"
                 src="https://oss.xiaoyi120.com/shop2.0/serClassfly/search_filter_0.png"
      />
      <van-image v-if="saleNum === 22" width="0.32rem" height="0.19rem"
                 src="https://oss.xiaoyi120.com/shop2.0/serClassfly/search_filter_1.png"
      />
      <van-image v-if="saleNum === 23" width="0.32rem" height="0.19rem"
                 src="https://oss.xiaoyi120.com/shop2.0/serClassfly/search_filter_2.png"
      />
    </div>
    <div :class="search_filter_tab === 3?'search_filter_item flex-r-c-c search_filter_active' : 'search_filter_item flex-r-c-c'"
         @click="changeFilter(3)"
    >
      <span>价格</span>
      <van-image v-if="price === 31" width="0.32rem" height="0.37rem"
                 src="https://oss.xiaoyi120.com/shop2.0/serClassfly/search_filter_0.png"
      />
      <van-image v-if="price === 32" width="0.32rem" height="0.19rem"
                 src="https://oss.xiaoyi120.com/shop2.0/serClassfly/search_filter_1.png"
      />
      <van-image v-if="price === 33" width="0.32rem" height="0.19rem"
                 src="https://oss.xiaoyi120.com/shop2.0/serClassfly/search_filter_2.png"
      />
    </div>
    <div v-if="type === 5"
         :class="search_filter_pupop ?'search_filter_item search_filter_active' : 'search_filter_item'"
         @click="changeFilter(7)"
    >
      <span>{{ search_filter_pupop?'条件已选':'条件筛选' }}</span>
      <van-icon name="filter-o" :color=" search_filter_pupop ? '#0767ab':''" />
    </div>
    <div v-if="type === 1 || type === 2 "
         :class="search_filter_tab === 4?'search_filter_item search_filter_active' : 'search_filter_item'"
         @click="changeFilter(4)"
    >
      <span>{{ popupName }}</span>
      <van-icon name="filter-o" :color=" search_filter_tab === 4? '#0767ab':''" />
      <!-- <van-icon name="wap-nav" :color=" search_filter_tab === 4? '#0767ab':''" /> -->
    </div>
    <div v-if="showPopup && type === 2" class="tab_4_popup flex-r-s-s">
      <div :class="tab4 === 41 ? 'popup_item popup_active':'popup_item'" @click="clickTab(41)">全部商品</div>
      <div :class="tab4 === 42 ? 'popup_item popup_active':'popup_item'" @click="clickTab(42)">超值通货</div>
      <div :class="tab4 === 43 ? 'popup_item popup_active':'popup_item'" @click="clickTab(43)">严选实惠自用</div>
      <div :class="tab4 === 44 ? 'popup_item popup_active':'popup_item'" @click="clickTab(44)">严选零售创收</div>
    </div>
    <div v-if="showPopup && type === 1" class="tab_4_popup1 ">
      <div class="popup_title">{{ popupTitle }}</div>
      <div :class="tab4 === 41 ? 'popup_item popup_active':'popup_item'" @click="clickTab(41)">全部商品</div>
      <div :class="tab4 === 42 ? 'popup_item popup_active':'popup_item'" @click="clickTab(42)">超值通货</div>
      <div :class="tab4 === 43 ? 'popup_item popup_active':'popup_item'" @click="clickTab(43)">严选实惠自用</div>
      <div :class="tab4 === 44 ? 'popup_item popup_active':'popup_item'" @click="clickTab(44)">严选零售创收</div>
    </div>
    <div v-if="showPopup2 && type > 1 && saleNum === 51" class="tab_category_popup flex-r-s-s">
      <div :class="tab4 === '' ? 'popup_item popup_active':'popup_item'" @click="clickTabcategory('')">
        全部</div>
      <div v-for="(category,index_category) in mallCategoryList" :key="index_category"
           :class="tab4 === category.id ? 'popup_item popup_active':'popup_item'"
           @click="clickTabcategory(category.id)"
      >{{ category.name }}</div>
    </div>
    <div v-if="showPopup3 && type > 1 && saleNum === 61" class="tab_category_popup flex-r-s-s">
      <div v-for="(brand,index_brand) in brandList" :key="index_brand"
           :class="tab4 === brand ? 'popup_item popup_active':'popup_item'" @click="clickTabbrand(brand)"
      >
        {{ brand }}</div>
    </div>
    <van-popup v-model="showPopup4" position="right" closeable :round="false"
               :style="{ height: '100%',width:'8.5rem' }"
    >
      <div class="popup_box">
        <div v-if="showtag" class="title">标签</div>
        <div v-if="showtag" class="box_item flex-r-s-s">
          <div :class="popup_box_tag === 0 ?'popup_item popup_active':'popup_item'" @click="clickpopupTab(0)">全部商品</div>
          <div :class="popup_box_tag === 1 ?'popup_item popup_active':'popup_item'" @click="clickpopupTab(1)">超值通货</div>
          <div :class="popup_box_tag === 2 ?'popup_item popup_active':'popup_item'" @click="clickpopupTab(2)">严选实惠自用
          </div>
          <div :class="popup_box_tag === 3 ?'popup_item popup_active':'popup_item'" @click="clickpopupTab(3)">严选零售创收
          </div>
        </div>
        <div class="title">品牌</div>
        <div class="box_item flex-r-s-s" style="max-height: 14rem;overflow-y: scroll;">
          <div v-for="(popup_box,popup_box_index) in popup_box_brand_list" :key="popup_box_index"
               :class="popup_box.checked ?'popup_item popup_active':'popup_item'" @click="clickpopupBrand(popup_box)"
          >
            {{ popup_box.name }}
          </div>
        </div>
        <div class="popup_buttom">
          <div class="popup_buttom_box flex-r-sa-c">
            <div class="left_bottom" @click="resetPopup">重置</div>
            <div class="right_bottom" @click="confirmPopup">确认</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { searchMallProductCategoryBrand, mallPreferential_brandList } from '@/api/pro'
export default {
  props: {
    name: { type: String, default: '' },
    cid: { type: String, default: '' },
    tagid: { type: String, default: '' },
    mid: { type: String, default: '' },
    type: { type: Number, default: 1 },
    showtag: { type: Boolean, default: true }
  },
  data() {
    return {
      search_filter_tab: 2,
      issaleNum: false,
      saleNum: 21,
      isprice: false,
      price: 31,
      popupName: '全部商品',
      showPopup: false,
      showPopup2: false,
      showPopup3: false,
      showPopup4: false,
      tab4: 41,
      popupTitle: '',
      mallCategoryList: [],
      brandList: [],
      search_filter_pupop: false,
      popup_box_tag: 0,
      popup_box_brand_list: [],
      brand_list_choosed: ['']
    }
  },
  methods: {
    // 顶部搜索切换
    changeFilter(index) {
      this.search_filter_tab = index
      if (index === 1) {
        this.isprice = false
        this.issaleNum = false
        this.saleNum = 21
        this.price = 31
        this.$emit('changeFilter', index)
      }
      if (index === 2) {
        this.issaleNum = !this.issaleNum
        this.saleNum = this.issaleNum ? 22 : 23
        this.isprice = false
        this.price = 31
        this.$emit('changeFilter', index)
      }
      if (index === 3) {
        this.isprice = !this.isprice
        this.price = this.isprice ? 32 : 33
        this.issaleNum = false
        this.saleNum = 21
        this.$emit('changeFilter', index)
      }
      if (index === 4) {
        this.showPopup = !this.showPopup
        this.showPopup2 = false
        this.$emit('changeFilter', index)
      }
      if (index === 5) {
        this.saleNum = 51
        this.showPopup = false
        this.showPopup2 = !this.showPopup2
        searchMallProductCategoryBrand({ proName: this.name, proCategoryId: this.cid, tagId: this.tagid, mallProductModuleId: this.mid }).then(res => {
          this.mallCategoryList = res.data.mallCategoryList
        })
      }
      if (index === 6) {
        this.saleNum = 61
        this.showPopup = false
        this.showPopup3 = !this.showPopup3
        searchMallProductCategoryBrand({ proName: this.name, proCategoryId: this.cid, tagId: this.tagid, mallProductModuleId: this.mid }).then(res => {
          this.brandList = res.data.brandList
        })
      }
      if (index === 7 && this.showtag) { // 侧面popup
        this.showPopup4 = true
        this.popup_box_brand_list = []
        console.log('brand_list_choosed', this.brand_list_choosed)
        searchMallProductCategoryBrand({ proName: '', proCategoryId: this.cid, tagId: this.tagid, mallProductModuleId: '' }).then(res => {
          var brandCopyList = []
          for (var i = 0; i < res.data.brandList.length; i++) {
            brandCopyList.push({ name: res.data.brandList[i], checked: false })
            for (var j = 0; j < this.brand_list_choosed.length; j++) {
              if (this.brand_list_choosed[j] === res.data.brandList[i]) {
                this.$set(brandCopyList[i], 'checked', true)
                break
              }
            }
          }
          this.popup_box_brand_list = brandCopyList
          console.log('popup_box_brand_list', this.popup_box_brand_list)
        })
      }
      if (index === 7 && !this.showtag) { // 侧面popup
        this.showPopup4 = true
        this.popup_box_brand_list = []
        console.log('brand_list_choosed', this.brand_list_choosed)
        mallPreferential_brandList({ proName: '', proCategoryId: this.cid }).then(res => {
          var brandCopyList = []
          for (var i = 0; i < res.data.length; i++) {
            brandCopyList.push({ name: res.data[i], checked: false })
            for (var j = 0; j < this.brand_list_choosed.length; j++) {
              if (this.brand_list_choosed[j] === res.data[i]) {
                this.$set(brandCopyList[i], 'checked', true)
                break
              }
            }
          }
          this.popup_box_brand_list = brandCopyList
          console.log('popup_box_brand_list', this.popup_box_brand_list)
        })
      }
    },
    clickpopupBrand(item) {
      item.checked = !item.checked
      if (item.checked) {
        this.brand_list_choosed.push(item.name)
        console.log('brand_list_choosed 0', this.brand_list_choosed)
      } else {
        console.log('item.name', item.name)
        console.log('brand_list_choosed 1', this.brand_list_choosed)
        for (const i in this.brand_list_choosed) {
          if (this.brand_list_choosed[i] === item.name) {
            this.brand_list_choosed.splice(i, 1)
          }
        }
      }
      console.log('brand_list_choosed 2', this.brand_list_choosed)
    },
    clickpopupTab(index) {
      this.brand_list_choosed = ['']
      this.popup_box_tag = index
      if (index === 0) this.tagid = ''
      if (index === 1) this.tagid = '282'
      if (index === 2) this.tagid = '281'
      if (index === 3) this.tagid = '275'
      searchMallProductCategoryBrand({ proName: '', proCategoryId: this.cid, tagId: this.tagid, mallProductModuleId: '' }).then(res => {
        var brandCopyList = []
        for (const i in res.data.brandList) {
          brandCopyList.push({ name: res.data.brandList[i], checked: false })
        }
        this.popup_box_brand_list = brandCopyList
      })
    },
    confirmPopup() {
      this.showPopup4 = false; this.search_filter_pupop = true
      this.$emit('popupSearch', { tagId: this.tagid, proBrands: this.brand_list_choosed.length === 1 ? [] : this.brand_list_choosed })
    },
    resetPopup() {
      this.brand_list_choosed = []
      this.showPopup4 = false; this.search_filter_pupop = false
      this.$emit('popupSearch', { tagId: this.tagid, proBrands: this.brand_list_choosed })
    },
    clickTab(index) {
      this.tab4 = index
      this.showPopup = false
      if (index === 41) this.popupName = '全部商品'
      if (index === 42) this.popupName = '超值通货'
      if (index === 43) this.popupName = '严选实惠自用'
      if (index === 44) this.popupName = '严选零售创收'
      this.$emit('changeFilter', index)
    },
    clickTabcategory(id) {
      this.tab4 = id
      this.showPopup2 = false
      this.$emit('changeFiltercategory', id)
    },
    clickTabbrand(brand) {
      this.tab4 = brand
      this.showPopup3 = false
      this.$emit('changeFilterbrand', brand)
    },
    rightTitle(title) {
      console.log('title', title)
      this.popupTitle = title
    },
    // 关闭展示
    closePullBox() {
      this.showPopup = false; this.showPopup2 = false; this.showPopup3 = false
      this.brand_list_choosed = ['']; this.popup_box_tag = 0; this.tagid = ''
      this.showPopup4 = false; this.search_filter_pupop = false
    }
  }
}
</script>

<style>
.search_filter .van-popup {
  border-top-left-radius: 0.26rem;
  border-bottom-left-radius: 0.26rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
.popup_box {
  padding: 0.43rem;
  position: relative;
}
.popup_box .title {
  font-weight: 700;
  font-size: 0.37rem;
  text-align: left;
  color: #333;
  margin-bottom: 0.2rem;
}
.popup_buttom {
  position: fixed;
  height: 1rem;
  width: 100%;
  bottom: 0;
  background: #fff;
  padding-bottom: 0.3rem;

  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.popup_buttom .popup_buttom_box {
  width: 7.95rem;
  height: 1.17rem;
  border-radius: 0.59rem;
  background: #fff;
  border: 0.03rem solid #0767ab;

  line-height: 1.17rem;
}
.popup_buttom .popup_buttom_box .left_bottom {
  width: 3.97rem;
  height: 1.17rem;
  border-radius: 0.59rem 0 0 0.59rem;
  background: #fff;

  font-weight: 400;
  font-size: 0.37rem;
  color: #0767ab;
}
.popup_buttom .popup_buttom_box .right_bottom {
  width: 3.97rem;
  height: 1.17rem;
  border-radius: 0 0.59rem 0.59rem 0;
  background: #0767ab;

  font-weight: 400;
  font-size: 0.43rem;
  color: #fff;
}
</style>
